body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.App-logo {
  margin: 0 10;
  height: 7vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #282c34;
  height: 10%;
  padding: 2px 5px 0px 0px;
  width: 100%;
}
.App-content {
  width: 100%;
  overflow: auto;
  /* background-image: linear-gradient(to right,  rgba(255, 255, 0, 0.445), rgba(0, 0, 255, 0.582)); */
  background-color: rgba(255, 255, 0, 0.308);
  height: 90%;
}
.App-footer {
  background: rgba(1, 22, 143, 0.281);
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 0%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.form {
  height: 50%;
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-item {
  width: 70%;
  display: flex;
  flex-direction: "row";
  justify-content: flex-end;
  /* align-items: center; */
}

.card {
  display: flex;
  flex-direction: row;
  justify-content: "space-evenly";
  flex-wrap: "wrap";
  background-color: #e7e781;
}

.card-item {
  background-color: #282c34;
  max-height: "155px";
}

.Database-logo {
  /* margin: 0 10; */
  height: 5vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Database-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

.error-message {
  color: red;
  font-weight: bold;
}
